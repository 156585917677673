import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import cookieCutter from 'cookie-cutter';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import Loading from 'components/organisms/global/loader';
import CartSnackbar from 'components/organisms/global/snack-bar/cart-snackbar';
import ModalCentered from 'components/organisms/modal/centered';
import { SegementCartTriggerSource } from 'helpers/analytics/cart-track';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { syncCarts } from 'helpers/cart';
import { AGE_GATE_COOKIE_NAME } from 'helpers/constants/cookies';
import { SegmentEvents } from 'helpers/constants/forms';
import useDispensary from 'helpers/hooks/use-dispensary';
import { THeaderVariantTypes } from 'helpers/types/organisms/global/header';
import Auth from 'helpers/utils/cognito';
import { getCookie } from 'helpers/utils/cookies';
import AvatarIcon from 'public/icons/figma/avatar.svg';
import ShoppingBag from 'public/icons/figma/bag-medium.svg';
import SearchBigIcon from 'public/icons/figma/search-big.svg';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setOpenLoginDrawer, setSignUpSource, setUser } from 'redux/reducers/account';
import { handleHiddenAgeGate } from 'redux/reducers/age-gate';
import { setCartTrigger } from 'redux/reducers/product-cart';
import { RootState } from 'redux/store';
import { CartQuantityCount, HeaderIconBox, HeaderIconCard, HeaderSearchIconBox, ProfileName } from './styles';
const CartDrawer = dynamic(() => import('components/molecules/cart'));

const Login = dynamic(() => import('components/organisms/drawers/login'), {
  loading: () => <Loading />,
});

type TProps = {
  variant: THeaderVariantTypes;
  handleClick: () => void;
};
const HeaderIcon = ({ variant, handleClick }: TProps) => {
  //TODO: fix the rerendering issue of the scroll
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountReducer.user);
  const cart = useSelector((state: RootState) => state.cart);
  const isLoginDrawerOpen = useAppSelector((state) => state.accountReducer.openLoginDrawer);
  const openLogin = () => {
    dispatch(setOpenLoginDrawer(true));
    dispatch(setSignUpSource('website-primary-nav'));
  };
  const [cartQuantity, setCartQuantity] = useState<any>(0);
  const { currentDispensary } = useDispensary();
  const currentStoreId = currentDispensary?.dispensaries?.storeId;
  const currentCart = cart.carts?.[currentStoreId];

  useEffect(() => {
    if (currentStoreId && !isEmpty(cart?.carts) && currentCart) {
      if (!isEmpty(currentCart.items)) {
        const totalCount = Object.values(currentCart.items).map((item) => item.quantity);
        const totalQuantity = currentCart ? totalCount.reduce((a, b) => a + b) : 0;
        setCartQuantity(totalQuantity);
      } else {
        setCartQuantity(0);
      }
    } else {
      setCartQuantity(0);
    }
  }, [cart, currentStoreId]);

  const router = useRouter();
  const [openCenteredModal, setOpenCenteredModal] = useState(false);
  const openLoginDrawer = () => {
    triggerNavigationClickEvent();
    const responseValidatedCookie = getCookie(AGE_GATE_COOKIE_NAME);
    if (responseValidatedCookie) {
      if (openCenteredModal) setOpenCenteredModal(false);
      openLogin();
    } else {
      dispatch(handleHiddenAgeGate('false'));
    }
  };
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);

  const { CartSnackbarComponent } = CartSnackbar(setIsCartDrawerOpen);
  const toggleCartDrawer = () => {
    setIsCartDrawerOpen(!isCartDrawerOpen);
    if (!isCartDrawerOpen) {
      dispatch(setCartTrigger(SegementCartTriggerSource.PRIMARY_NAV));
    }
  };

  useEffect(() => {
    if (router.query?.loginToken && router.query?.username) {
      // login token found signin user
      const refreshToken: any = router.query?.loginToken;
      const cognitoUsername: any = router.query?.username;
      (async () => {
        await Auth.refreshSession(cognitoUsername, refreshToken);
        const updatedUser = await Auth.getUpdatedUser();
        dispatch(setUser(updatedUser));
        const urlObj = new URL(router.asPath);
        ['loginToken', 'username'].forEach((param) => urlObj.searchParams.delete(param));
        const updatedUrl = urlObj.toString();
        router.replace(updatedUrl);
      })().catch((err) => {
        console.error('Error refreshing token', err);
      });
    }
  }, []);

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    const currentUser = Auth.getCurrentUser();
    if (!currentUser && router.query?.flow === 'signup') {
      openLogin();
    }
    if (user && currentStoreId) {
      syncCarts();
    }
  }, [user, currentStoreId]);

  useEffect(() => {
    const queryParams = router.query;
    if (!user && queryParams?.path === '/' && queryParams?.email_verification === 'true' && queryParams?.code) {
      setOpenCenteredModal(true);
    }
  }, []);
  useEffect(() => {
    if (cookieCutter.get('isUser') === 'false') {
      Auth.signOut();
      dispatch(setUser(null));
    }
  }, []);

  const checkUser = async () => {
    await Auth.getSession();
  };

  const triggerNavigationClickEvent = () => {
    navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
      nav_position: 'header',
      link_type: 'profile-icon',
    });
  };

  return (
    <>
      <HeaderIconCard>
        <HeaderSearchIconBox $variant={variant} onClick={handleClick} data-testid="search-icon">
          <SearchBigIcon />
        </HeaderSearchIconBox>
        {user ? (
          <HeaderIconBox
            $as={'a'}
            href={'/account/'}
            onClick={triggerNavigationClickEvent}
            $variant={variant}
            className="flex cursor-pointer items-center"
            data-testid="user-avatar"
          >
            <AvatarIcon size={'1.5em'} className={user ? 'text-kief' : ''} />
            <ProfileName
              variant="body-small-bold"
              className={`hover:underline ${
                variant === 'dark' ? 'pl-2.5 text-white hover:text-kief' : 'pl-3 text-leaf'
              }`}
            >
              {user?.attributes?.given_name}
            </ProfileName>
          </HeaderIconBox>
        ) : (
          <HeaderIconBox $as={'a'} $variant={variant} className="flex cursor-pointer items-center">
            <AvatarIcon
              onClick={openLoginDrawer}
              size={'1.5em'}
              className={user ? 'text-kief' : ''}
              data-testid="login-avatar"
            />
          </HeaderIconBox>
        )}
        <HeaderIconBox $variant={variant}>
          <div className="relative flex items-center">
            <ShoppingBag size={'1.5em'} onClick={toggleCartDrawer} data-testid="shopping-bag" />
            {cartQuantity != 0 && (
              <CartQuantityCount data-testid="shopping-bag-count">{cartQuantity}</CartQuantityCount>
            )}
          </div>
        </HeaderIconBox>
        {isLoginDrawerOpen && <Login />}
        {isCartDrawerOpen && <CartDrawer open={isCartDrawerOpen} onClose={toggleCartDrawer} />}
      </HeaderIconCard>
      {openCenteredModal && (
        <ModalCentered
          title={'You will need to login to verify your email ID'}
          outsideControl={true}
          openFromParent={openCenteredModal}
          handleOpenFromParent={(x) => {
            setOpenCenteredModal(x);
          }}
          buttonCtaLabel={'Login'}
          onClick={openLoginDrawer}
        />
      )}
      <CartSnackbarComponent />
    </>
  );
};

export default HeaderIcon;
